import 'virtual:svg-icons-register' // TODO: Remove once we migrate all icons to V2Icon
import 'windi.css'
import '@consumer/styles/global.scss'
import '@shared/timeZone'

import FloatingVue, { Tooltip } from 'floating-vue'

import { MotionPlugin } from '@vueuse/motion'
import { createApp } from '@shared/app'
import { createSSRApp as createAppWith } from 'vue'
import { createPinia } from 'pinia'
import { maska } from 'maska'
import pages from '@consumer/pages'
import VueGoogleMaps from '@fawmi/vue-google-maps'

const pinia = createPinia()

if (document.getElementById('app')) {
  createApp('consumer', pages, (app) => {
    app
      .use(pinia)
      .directive('mask', maska)
      .component('GTooltip', Tooltip)
      .use(FloatingVue, {
        themes: {
          'top-nav-dropdown': { $extend: 'dropdown' },
        },
      })
      .use(MotionPlugin)
      .use(VueGoogleMaps, {
        load: {
          key: import.meta.env.GOOGLE_MAPS_JS_API_KEY,
        },
      })
  }, { createAppWith })
}
